// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-version {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    text-align: center;
    right: 0;
    font-size: 0.75rem;
    color: var(--app-border-secondary-color);
    padding-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/AppVersion/AppVersion.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,kBAAkB;IAClB,wCAAwC;IACxC,oBAAoB;AACxB","sourcesContent":[".app-version {\n    position: absolute;\n    bottom: 2rem;\n    width: 100%;\n    text-align: center;\n    right: 0;\n    font-size: 0.75rem;\n    color: var(--app-border-secondary-color);\n    padding-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
