// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-icon-btn__label{
    position: absolute;
    bottom: 0;
    font-size: 0.65rem!important;
    font-weight: bold!important;
}
.menu-icon-btn__icon{
    padding: 5px 5px 3px 5px;
}

.menu-icon-btn__list-item.MuiIconButton-root{
    color: #000!important;
}
.menu-icon-btn__list-item.MuiIconButton-root.MuiIconButton-colorSecondary{
    color: var(--app-fill-color-primary-one)!important;
}
.MuiIconButton-root.menu-icon-btn.MuiIconButton-colorSecondary{
    color: var(--app-fill-color-primary-one)!important;
}

.MuiIconButton-root.menu-icon-btn.MuiIconButton-colorSecondary:hover,
.MuiIconButton-root.menu-icon-btn.MuiIconButton-colorSecondary:active,
.MuiIconButton-root.menu-icon-btn.MuiIconButton-colorSecondary:focus {
    background-color: rgba(255,255,255,0);
}

.MuiIconButton-root.menu-icon-btn__list-item:hover{
    background-color: transparent;
}

.MuiIconButton-root.menu-icon-btn__list-item .menu-icon-btn__icon{
    padding: 5px 16px 3px 5px;
}

.MuiIconButton-root.menu-icon-btn{
    font-size: 1.1rem;
}

.menu-icon-btn.menu-icon-btn__list-item.aside-nav-link .menu-icon-btn__icon{
    padding-right: 0;
    padding-top: 0;
}

.menu-icon-btn.menu-icon-btn__list-item.aside-nav-link .MuiIconButton-label {
    gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/core/components/buttons/MenuIconButton/MenuIconButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,4BAA4B;IAC5B,2BAA2B;AAC/B;AACA;IACI,wBAAwB;AAC5B;;AAEA;IACI,qBAAqB;AACzB;AACA;IACI,kDAAkD;AACtD;AACA;IACI,kDAAkD;AACtD;;AAEA;;;IAGI,qCAAqC;AACzC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,SAAS;AACb","sourcesContent":[".menu-icon-btn__label{\n    position: absolute;\n    bottom: 0;\n    font-size: 0.65rem!important;\n    font-weight: bold!important;\n}\n.menu-icon-btn__icon{\n    padding: 5px 5px 3px 5px;\n}\n\n.menu-icon-btn__list-item.MuiIconButton-root{\n    color: #000!important;\n}\n.menu-icon-btn__list-item.MuiIconButton-root.MuiIconButton-colorSecondary{\n    color: var(--app-fill-color-primary-one)!important;\n}\n.MuiIconButton-root.menu-icon-btn.MuiIconButton-colorSecondary{\n    color: var(--app-fill-color-primary-one)!important;\n}\n\n.MuiIconButton-root.menu-icon-btn.MuiIconButton-colorSecondary:hover,\n.MuiIconButton-root.menu-icon-btn.MuiIconButton-colorSecondary:active,\n.MuiIconButton-root.menu-icon-btn.MuiIconButton-colorSecondary:focus {\n    background-color: rgba(255,255,255,0);\n}\n\n.MuiIconButton-root.menu-icon-btn__list-item:hover{\n    background-color: transparent;\n}\n\n.MuiIconButton-root.menu-icon-btn__list-item .menu-icon-btn__icon{\n    padding: 5px 16px 3px 5px;\n}\n\n.MuiIconButton-root.menu-icon-btn{\n    font-size: 1.1rem;\n}\n\n.menu-icon-btn.menu-icon-btn__list-item.aside-nav-link .menu-icon-btn__icon{\n    padding-right: 0;\n    padding-top: 0;\n}\n\n.menu-icon-btn.menu-icon-btn__list-item.aside-nav-link .MuiIconButton-label {\n    gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
