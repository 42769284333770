// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-values__item--inactive .filter-checkbox.MuiCheckbox-colorSecondary.Mui-checked svg rect{
    --app-fill-color-primary-one: var(--app-border-secondary-color);
}

.filter-checkbox.MuiCheckbox-colorSecondary.Mui-checked{
    color: var(--app-fill-color-primary-one);
    fill: var(--app-fill-color-primary-one);
    opacity: 1;
}
.filter-checkbox.MuiCheckbox-colorSecondary.Mui-checked svg rect{
    color: var(--app-fill-color-primary-one);
    fill: var(--app-fill-color-primary-one);
    opacity: 1;
}

.filter-checkbox.MuiIconButton-colorSecondary:hover,
.filter-checkbox.MuiCheckbox-colorSecondary.Mui-checked:hover{
    background-color: transparent!important;
}
.filter-values{
    display: flex;
    flex-direction: column;
}
.filter-values__item .MuiFormControlLabel-label{
    padding-left: 9px;
}
.filter-group-name{
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 7px;
    line-height: 28px;
}
.filter{
    padding-bottom: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/selectors/Filter/styles/Filter.css"],"names":[],"mappings":"AAAA;IACI,+DAA+D;AACnE;;AAEA;IACI,wCAAwC;IACxC,uCAAuC;IACvC,UAAU;AACd;AACA;IACI,wCAAwC;IACxC,uCAAuC;IACvC,UAAU;AACd;;AAEA;;IAEI,uCAAuC;AAC3C;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,oBAAoB;AACxB","sourcesContent":[".filter-values__item--inactive .filter-checkbox.MuiCheckbox-colorSecondary.Mui-checked svg rect{\n    --app-fill-color-primary-one: var(--app-border-secondary-color);\n}\n\n.filter-checkbox.MuiCheckbox-colorSecondary.Mui-checked{\n    color: var(--app-fill-color-primary-one);\n    fill: var(--app-fill-color-primary-one);\n    opacity: 1;\n}\n.filter-checkbox.MuiCheckbox-colorSecondary.Mui-checked svg rect{\n    color: var(--app-fill-color-primary-one);\n    fill: var(--app-fill-color-primary-one);\n    opacity: 1;\n}\n\n.filter-checkbox.MuiIconButton-colorSecondary:hover,\n.filter-checkbox.MuiCheckbox-colorSecondary.Mui-checked:hover{\n    background-color: transparent!important;\n}\n.filter-values{\n    display: flex;\n    flex-direction: column;\n}\n.filter-values__item .MuiFormControlLabel-label{\n    padding-left: 9px;\n}\n.filter-group-name{\n    font-weight: 700;\n    font-size: 1rem;\n    margin-bottom: 7px;\n    line-height: 28px;\n}\n.filter{\n    padding-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
