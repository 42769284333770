import theme from '../default/theme';

const ttyTheme = { ...theme };

ttyTheme.palette = {
    ...theme.palette,
    background: {
        default: '#eee',
        paper: '#fff',
    },
    secondary: {
        ...theme.palette.secondary,
        main: '#4444AA',
    },
};

export default ttyTheme;
