// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-icon-toggle-btn {
    color: var(--app-menu-icon__text-color--secondary);
    font-weight: var(--app-font-weigth--600);
}

.menu-icon-toggle-btn .custom-menu-button__label{
    transform: translateY(3px);
    bottom: 0;

}
.menu-icon-toggle-btn__button{
    padding: 12px;
    position: relative;
    width: 51px;
    height:54px ;
    display: flex;
}
.menu-icon-toggle-btn__icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: opacity 0.20s ease-in-out;
    -moz-transition: opacity 0.20s ease-in-out;
    -webkit-transition: opacity 0.20s ease-in-out;
    fill: var(--app-menu-icon__text-color--secondary);

}
.menu-icon-toggle-btn__icon path{
    fill: var(--app-menu-icon__text-color--secondary);

}
.menu-icon-toggle-btn__icon--hidden{
    opacity: 0!important;

}
.menu-icon-toggle-btn__icon--active{
    opacity: 1!important;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/buttons/MenuToggleButton/MenuToggleButton.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,wCAAwC;AAC5C;;AAEA;IACI,0BAA0B;IAC1B,SAAS;;AAEb;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,+BAA+B;IAC/B,qCAAqC;IACrC,0CAA0C;IAC1C,6CAA6C;IAC7C,iDAAiD;;AAErD;AACA;IACI,iDAAiD;;AAErD;AACA;IACI,oBAAoB;;AAExB;AACA;IACI,oBAAoB;AACxB","sourcesContent":[".menu-icon-toggle-btn {\n    color: var(--app-menu-icon__text-color--secondary);\n    font-weight: var(--app-font-weigth--600);\n}\n\n.menu-icon-toggle-btn .custom-menu-button__label{\n    transform: translateY(3px);\n    bottom: 0;\n\n}\n.menu-icon-toggle-btn__button{\n    padding: 12px;\n    position: relative;\n    width: 51px;\n    height:54px ;\n    display: flex;\n}\n.menu-icon-toggle-btn__icon{\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%,-50%);\n    transition: opacity 0.20s ease-in-out;\n    -moz-transition: opacity 0.20s ease-in-out;\n    -webkit-transition: opacity 0.20s ease-in-out;\n    fill: var(--app-menu-icon__text-color--secondary);\n\n}\n.menu-icon-toggle-btn__icon path{\n    fill: var(--app-menu-icon__text-color--secondary);\n\n}\n.menu-icon-toggle-btn__icon--hidden{\n    opacity: 0!important;\n\n}\n.menu-icon-toggle-btn__icon--active{\n    opacity: 1!important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
