import { useMemo } from 'react';
import useWindowSize, { VIEWPORT_BREAKPOINTS } from '../../hooks/useWindowSize';

import { LinearProgress } from '@material-ui/core';
import { LogoLogin } from '../Logo';

import './styles/Intro.css';
import { useSelector } from 'react-redux';
import { AppState } from 'core/redux/rootReducer';

export const Intro: IntroType = ({ isLoading = false, classes = '' }: IntroProps) => {
    const { getScreenWidthClassName } = useWindowSize();
    const SCREEN_CLASSNAME = useMemo(
        () => getScreenWidthClassName(VIEWPORT_BREAKPOINTS.w680),
        [getScreenWidthClassName],
    );
    const isMobileWidth = useSelector(({ core }: AppState) => core.isMobileWidth);

    if (isMobileWidth) return <div />;

    const wrapperClasses = `company-intro company-intro__container company-intro__container--${SCREEN_CLASSNAME} ${classes}`;
    const innerClasses = `company-intro__wrapper company-intro__wrapper--${SCREEN_CLASSNAME}`;

    return (
        <div className={wrapperClasses}>
            <div className={innerClasses}>
                <LogoLogin classes={`company-intro__logo ${isLoading ? '' : 'company-intro__logo--desktop'}`} />
                {isLoading && <LinearProgress className="company-intro__loading" />}
            </div>
        </div>
    );
};

export default Intro;

type IntroType = (props: IntroProps) => JSX.Element;
export type IntroProps = {
    isLoading?: boolean;
    classes?: string;
};
