// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiAppBar-root.nav-bar{
    bottom: 0!important;
    top: auto!important;
    padding: 0 calc(env(safe-area-inset-right,0) + 5px) calc(env(safe-area-inset-bottom,8px) + 8px) calc(env(safe-area-inset-left,0) + 5px);
    font-size: 1.25rem;
    /*box-shadow: 0 0 16px 0 black 5%;*/
    z-index: 101;
}
.MuiPaper-root.nav-bar{
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
}
.nav-bar.MuiAppBar-colorDefault{
    background-color: #fff;
}
.nav-bar__toolbar{
    justify-content: space-between;

}

a.nav-bar__menu-link,
a.nav-bar__menu-link:link,
a.nav-bar__menu-link:hover,
a.nav-bar__menu-link:focus,
a.nav-bar__menu-link:active,
a.nav-bar__menu-link:visited{
    text-decoration: none!important;
    outline: none!important;
    background-color: transparent!important;
    text-decoration-color: transparent!important;
    border: none!important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/menu/AppNavBar/AppNavBar.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,uIAAuI;IACvI,kBAAkB;IAClB,mCAAmC;IACnC,YAAY;AAChB;AACA;IACI,0CAA0C;AAC9C;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,8BAA8B;;AAElC;;AAEA;;;;;;IAMI,+BAA+B;IAC/B,uBAAuB;IACvB,uCAAuC;IACvC,4CAA4C;IAC5C,sBAAsB;IACtB,2BAA2B;IAC3B,yBAAyB;IACzB,sBAAsB;IACtB,qBAAqB;IACrB,iBAAiB;IACjB,wCAAwC;IACxC,mBAAmB;AACvB","sourcesContent":[".MuiAppBar-root.nav-bar{\n    bottom: 0!important;\n    top: auto!important;\n    padding: 0 calc(env(safe-area-inset-right,0) + 5px) calc(env(safe-area-inset-bottom,8px) + 8px) calc(env(safe-area-inset-left,0) + 5px);\n    font-size: 1.25rem;\n    /*box-shadow: 0 0 16px 0 black 5%;*/\n    z-index: 101;\n}\n.MuiPaper-root.nav-bar{\n    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);\n}\n.nav-bar.MuiAppBar-colorDefault{\n    background-color: #fff;\n}\n.nav-bar__toolbar{\n    justify-content: space-between;\n\n}\n\na.nav-bar__menu-link,\na.nav-bar__menu-link:link,\na.nav-bar__menu-link:hover,\na.nav-bar__menu-link:focus,\na.nav-bar__menu-link:active,\na.nav-bar__menu-link:visited{\n    text-decoration: none!important;\n    outline: none!important;\n    background-color: transparent!important;\n    text-decoration-color: transparent!important;\n    border: none!important;\n    -webkit-touch-callout: none;\n    -webkit-user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n    -webkit-tap-highlight-color: transparent;\n    white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
