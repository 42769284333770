import React from 'react';
import { matchPath, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { filterSideBar } from '../helpers';
import { IMenuItem } from 'core/types/redux/MenuReducerState';
import { BreakPointRange } from 'core/classes/WindowSize';
import { MENU_ITEM_ID } from '../../../../config/menu_config';
import MenuIconButton from '../../../buttons/MenuIconButton';
import { useSelector } from 'react-redux';
import { forNotActiveAgreement } from '../../AppNavBar/helpers/filterButtonVisibilityFromNavBar';
import { hasActiveAgreementsSelector } from 'agreement/redux/agreementsReducer';

interface SideBarMenuComponentListProps {
    sideBarMenuIds: string[];
    menuItems: Record<string, IMenuItem>;
    windowSizeRange?: BreakPointRange;
    isOpen: boolean;
    pathname: string;
    hasUnreadMessages: boolean;
}

const SideBarMenuComponentList: React.FC<SideBarMenuComponentListProps> = ({
    sideBarMenuIds,
    menuItems,
    windowSizeRange,
    isOpen,
    pathname,
    hasUnreadMessages,
}) => {
    const { t } = useTranslation();
    const shouldNotFilterIds = useSelector(hasActiveAgreementsSelector);

    const filteredIds = shouldNotFilterIds
        ? sideBarMenuIds.filter((id) => {
              if (!menuItems[id]) {
                  return false;
              }
              return filterSideBar(menuItems[id], windowSizeRange);
          })
        : sideBarMenuIds.filter(forNotActiveAgreement).filter((id) => {
              if (!menuItems[id]) {
                  return false;
              }
              return filterSideBar(menuItems[id], windowSizeRange);
          });

    return (
        <>
            {filteredIds
                .filter((id) => filterSideBar(menuItems[id], windowSizeRange))
                .map((id) => {
                    const { mainRoute, iconName, labelText, secondaryRoutes, icon } = menuItems[id];
                    const activePath = secondaryRoutes?.some((secondary) => !!matchPath(secondary, pathname));
                    const inlineLabelText = t('menu.' + labelText);

                    return (
                        <NavLink key={id} className="menu-list__item" to={mainRoute}>
                            <MenuIconButton
                                classes={'aside-nav-link'}
                                iconColor={activePath ? 'secondary' : 'default'}
                                iconName={iconName}
                                labelText={labelText}
                                inlineLabelText={inlineLabelText}
                                isNavBarMenuItem={false}
                                labelHidden={!isOpen}
                                hasBadge={id === MENU_ITEM_ID.MESSAGING && hasUnreadMessages}
                                badgeColor={'secondary'}
                                icon={icon}
                            />
                        </NavLink>
                    );
                })}
        </>
    );
};

export default React.memo(SideBarMenuComponentList);
