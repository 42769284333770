import { SERVICE_REQUEST } from '../../core/datasets/action';
import { ChangeEvent } from 'react';
import {
    ResetServiceRequestFormAction,
    ServiceRequestAddFilesAction,
    ServiceRequestChangeFieldAction,
    SetCreatedServiceRequestIdAction,
} from '../types/redux/serviceRequestActions';

export const changeServiceRequestFieldValue = ({
    target,
}: ChangeEvent<HTMLInputElement>): ServiceRequestChangeFieldAction => {
    const { name, value } = target;

    return {
        type: SERVICE_REQUEST.CHANGE_FIELD_VALUE,
        fieldName: name,
        payload: {
            value: value as string,
        },
    };
};

export const addServiceRequestFiles = (files: File[]): ServiceRequestAddFilesAction => {
    return {
        type: SERVICE_REQUEST.ADD_FILES,
        payload: files,
    };
};

export const selectRentalObjectValue = (
    event: ChangeEvent<{ value: unknown; name?: unknown }>,
): ServiceRequestChangeFieldAction => {
    const { value, name } = event.target;

    return {
        type: SERVICE_REQUEST.CHANGE_FIELD_VALUE,
        fieldName: name as string,
        payload: {
            value: value as string,
        },
    };
};

export const setServiceTypeValue = (value: string): ServiceRequestChangeFieldAction => {
    return {
        type: SERVICE_REQUEST.CHANGE_FIELD_VALUE,
        fieldName: 'serviceType',
        payload: {
            value: value as string,
        },
    };
};

export const setRentalObjectSelectValue = (value: string): ServiceRequestChangeFieldAction => {
    return {
        type: SERVICE_REQUEST.CHANGE_FIELD_VALUE,
        fieldName: 'rentalObjectSelect',
        payload: {
            value: value as string,
        },
    };
};

export const resetServiceRequestFormValues = (): ResetServiceRequestFormAction => {
    return {
        type: SERVICE_REQUEST.RESET_FORM,
    };
};

export const setCreatedServiceRequestId = (id: string | null): SetCreatedServiceRequestIdAction => {
    return {
        type: SERVICE_REQUEST.SET_NEW_REQUEST_ID,
        payload: id,
    };
};
