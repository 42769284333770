import { ApolloProvider } from '@apollo/react-hooks';
import ErrorPage from 'pages/ErrorPage';
import { Provider } from 'react-redux';
import { persistor, store } from 'core/redux/store';
import RoutesSwitcher from 'core/components/RoutesSwitcher';
import { client } from 'core/config/gql/client';
import { PersistGate } from 'redux-persist/es/integration/react';
import { getApps, initializeApp } from 'firebase/app';
import { config } from 'core/config/firebase';
import { i18n, setupConfig } from '@larva.io/webcomponents-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import 'core/config/tranlsations/i18n';

import ConnectionStatusNotifier from 'core/components/ConnectionStatusNotifier';
import PushNotification from 'core/components/PushNotification/PushNotification';
import AlertNotifications from 'core/components/modalsPortalsNotices/AlertNotifications';
import { AppUrlListener } from './core/components/RoutesSwitcher/AppUrlListener';
import larvaEng from './translations/larva/en.json';
import larvaEt from './translations/larva/et.json';
import larvaPl from './translations/larva/pl.json';

import './App.css';

if (!getApps().length) {
    initializeApp(config);
}

setupConfig({
    //@ts-ignore
    //videoproxyURL: LarvaGeneralConfig ? LarvaGeneralConfig.videoProxy : '',
});

// This commands add larva translations. Larva uses own separate instance of i18n.
// If you delete this, larva well always fallback to english.
i18n.addResourceBundle('en', 'larva', larvaEng, true, true);
i18n.addResourceBundle('et', 'larva', larvaEt, true, true);
i18n.addResourceBundle('pl', 'larva', larvaPl, true, true);

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://0632bb21316c48f4bd3ee43884b9f64e@o290557.ingest.sentry.io/5690141',
        integrations: [new Integrations.BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.05,
        debug: false,
    });

    //@ts-ignore
    (function (h, o, t, j, a, r) {
        //@ts-ignore
        h.hj =
            //@ts-ignore
            h.hj ||
            function () {
                //@ts-ignore
                // eslint-disable-next-line prefer-rest-params
                (h.hj.q = h.hj.q || []).push(arguments);
            };
        //@ts-ignore
        h._hjSettings = { hjid: 2602991, hjsv: 6 };
        //@ts-ignore
        a = o.getElementsByTagName('head')[0];
        //@ts-ignore
        r = o.createElement('script');
        //@ts-ignore
        r.async = 1;
        //@ts-ignore
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        //@ts-ignore
        a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export function AppRoot(): JSX.Element {
    return (
        <Sentry.ErrorBoundary fallback={<ErrorPage message={'Something went wrong, sorry! :('} />}>
            <Provider store={store}>
                <ApolloProvider client={client as any}>
                    <PersistGate persistor={persistor}>
                        <ConnectionStatusNotifier />
                        <PushNotification />
                        <AppUrlListener />
                        <RoutesSwitcher />
                        <AlertNotifications />
                    </PersistGate>
                </ApolloProvider>
            </Provider>
        </Sentry.ErrorBoundary>
    );
}
