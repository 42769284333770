// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tenant-registration-form{
    width: 100%;
    max-width: 407px;
    padding: 0 0.5rem 2rem;
}
.tenant-registration-form__header{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 2rem;
}
.tenant-registration-form__header .app-logo{
    visibility: hidden;
}
@media screen and (min-width: 680px){
    .tenant-registration-form__header .app-logo{
        visibility: visible;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/TenantRegistration/components/TenantRegistrationForm/TenantRegistrationForm.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,WAAW;IACX,8BAA8B;IAC9B,iBAAiB;AACrB;AACA;IACI,kBAAkB;AACtB;AACA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".tenant-registration-form{\n    width: 100%;\n    max-width: 407px;\n    padding: 0 0.5rem 2rem;\n}\n.tenant-registration-form__header{\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    padding: 0 0 2rem;\n}\n.tenant-registration-form__header .app-logo{\n    visibility: hidden;\n}\n@media screen and (min-width: 680px){\n    .tenant-registration-form__header .app-logo{\n        visibility: visible;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
