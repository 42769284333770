import { DateTime } from 'luxon';
import i18n from 'i18next';
import { getLocalByLanguage } from './getLocalByLanguage';

export function getTimeString(date: DateTime) {
    let days7ago = DateTime.now();
    days7ago = days7ago.minus({ days: 6 });

    const isToday = date.toISODate() === DateTime.local().toISODate();
    const isYesterday = date.toISODate() === DateTime.local().minus({ day: 1 }).toISODate();
    const sameYear = date.year === DateTime.local().year;
    const locale = getLocalByLanguage(i18n.language);

    if (date.locale !== locale) {
        date = date.reconfigure({ locale });
    }

    if (isToday) {
        return i18n.t(`messaging.today`);
    }
    if (isYesterday) {
        return i18n.t(`messaging.yesterday`);
    }
    if (sameYear) {
        return date.toLocaleString({ month: 'long', day: '2-digit' });
    }
    return date.toLocaleString(DateTime.DATE_FULL);
}
