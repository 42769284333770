export function getLocalByLanguage(lang: string) {
    const localeAliases: { [key: string]: string } = {
        en: 'en-US',
        et: 'et-EE',
        pl: 'pl-PL',
        lv: 'lv-LV',
        ro: 'ro-RO',
        de: 'de-DE',
    };

    return localeAliases[lang] ?? 'en-US';
}
