// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-button-badge .MuiBadge-anchorOriginTopRightRectangle{
    top: -6px;
    right: -6px;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/buttons/FilterButton/FilterButton.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,WAAW;AACf","sourcesContent":[".filter-button-badge .MuiBadge-anchorOriginTopRightRectangle{\n    top: -6px;\n    right: -6px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
