// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support-wrapper {
    min-height: auto;
}

@media screen and (max-width:674px) {
    .support-wrapper {
        min-height: 489px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Suppport/Support.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".support-wrapper {\n    min-height: auto;\n}\n\n@media screen and (max-width:674px) {\n    .support-wrapper {\n        min-height: 489px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
