// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-checkbox.MuiCheckbox-root {
    color: var(--app-border-secondary-color-v30);

    border: 1px;
    padding: 0 10px;
}
.terms-checkbox.MuiCheckbox-colorSecondary.Mui-checked{
    color: var(--app-fill-color-primary-one);
    opacity: 1;
}

.terms-checkbox.MuiCheckbox-colorSecondary:hover{
    background-color: transparent;
}

.terms-checkbox.MuiCheckbox-colorSecondary.Mui-checked svg rect{
    color: var(--app-fill-color-primary-one);
    fill: var(--app-fill-color-primary-one);
    opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/TermsAndConditionsCheck/styles/TermsCheckbox.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;;IAE5C,WAAW;IACX,eAAe;AACnB;AACA;IACI,wCAAwC;IACxC,UAAU;AACd;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,wCAAwC;IACxC,uCAAuC;IACvC,UAAU;AACd","sourcesContent":[".terms-checkbox.MuiCheckbox-root {\n    color: var(--app-border-secondary-color-v30);\n\n    border: 1px;\n    padding: 0 10px;\n}\n.terms-checkbox.MuiCheckbox-colorSecondary.Mui-checked{\n    color: var(--app-fill-color-primary-one);\n    opacity: 1;\n}\n\n.terms-checkbox.MuiCheckbox-colorSecondary:hover{\n    background-color: transparent;\n}\n\n.terms-checkbox.MuiCheckbox-colorSecondary.Mui-checked svg rect{\n    color: var(--app-fill-color-primary-one);\n    fill: var(--app-fill-color-primary-one);\n    opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
