import { QUERY_FETCH_POLICY } from 'core/hooks/useQueryHelper';
import { gql, useQuery } from '@apollo/client';
import { IChannelFromBE } from '../types/messages';
import { keyBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { messagingActions } from '../redux/messagingActions';
import { sortChannelsByLastActivity } from '../utils';

export const ChannelsQuery = gql`
    query messagingChannelList {
        messagingChannelList {
            channelId
            channelType
            lastActivity
            lastMessage
            messagesCount
            serviceProviderUsers {
                fullName
                externalUuid
            }
            serviceRequestData {
                id
                title
                type
            }
        }
    }
`;

export default function useQueryChannels(skip = false) {
    const dispatch = useDispatch();

    const { data, loading, error, called, refetch } = useQuery<{ messagingChannelList: IChannelFromBE[] }>(
        ChannelsQuery,
        { ...QUERY_FETCH_POLICY, skip },
    );

    const channels = sortChannelsByLastActivity(data?.messagingChannelList);

    const channelsMap = keyBy(channels, 'channelId');

    useEffect(() => {
        if (data) dispatch(messagingActions.setChannelsMap(channelsMap));
    }, [data]);

    const refetchAndUpdate = () =>
        refetch()
            .then(({ data }) => {
                const sortedChannels = sortChannelsByLastActivity(data?.messagingChannelList);
                const channelsMap = keyBy(sortedChannels, 'channelId');
                dispatch(messagingActions.setChannelsMap(channelsMap));
            })
            .catch((error) => {
                console.error(error);
            });

    return {
        loading,
        error,
        channels,
        called,
        refetchAndUpdate,
        channelsMap,
    };
}
