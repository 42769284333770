// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-account-item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 2rem;
    max-width: 376px;
}

@media screen and (min-width: 320px) {
    .delete-account-item {
        max-width: 288px;
    }
}

@media screen and (min-width: 678px) {
    .delete-account-item {
        max-width: 376px;
    }
}

/* Stupid solution to keep button height same when loading indicator is shown */
.confirm-button {
    height: 48px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Settings/components/SettingsList/styles/DeleteAccount.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0BAA0B;IAC1B,wBAAwB;IACxB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ;;AAEA,+EAA+E;AAC/E;IACI,YAAY;AAChB","sourcesContent":[".delete-account-item {\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-template-rows: auto;\n    gap: 2rem;\n    max-width: 376px;\n}\n\n@media screen and (min-width: 320px) {\n    .delete-account-item {\n        max-width: 288px;\n    }\n}\n\n@media screen and (min-width: 678px) {\n    .delete-account-item {\n        max-width: 376px;\n    }\n}\n\n/* Stupid solution to keep button height same when loading indicator is shown */\n.confirm-button {\n    height: 48px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
