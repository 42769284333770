// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-item-with-arrow-btn{
    font-family: var(--app-font-family);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--app-text-color-primary);
    cursor: pointer;
}

.list-item-with-arrow-btn__icon-text{
    display: flex;
    align-items: center;
}

.list-item-with-arrow-btn__text{
   padding-left: 10px;
}

.list-item-with-arrow-btn__icon  path{
    fill: var(--app__icon--default-fill-color);
}

`, "",{"version":3,"sources":["webpack://./src/core/components/buttons/ListItemWithArrow/ListItemWithArrow.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,oCAAoC;IACpC,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;GACG,kBAAkB;AACrB;;AAEA;IACI,0CAA0C;AAC9C","sourcesContent":[".list-item-with-arrow-btn{\n    font-family: var(--app-font-family);\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    color: var(--app-text-color-primary);\n    cursor: pointer;\n}\n\n.list-item-with-arrow-btn__icon-text{\n    display: flex;\n    align-items: center;\n}\n\n.list-item-with-arrow-btn__text{\n   padding-left: 10px;\n}\n\n.list-item-with-arrow-btn__icon  path{\n    fill: var(--app__icon--default-fill-color);\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
