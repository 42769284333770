// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-menu-button__container{
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: relative;
    align-items:center ;
}
.MuiIconButton-root.custom-menu-button__icon-button{
    padding: 0;
}
.custom-menu-button__label{
    position: absolute;
    font-size: 0.65rem;
    transform: translateY(1rem);
    cursor: pointer;
}

.custom-menu-button__badge .MuiBadge-anchorOriginTopRightRectangle{
    transform:  translate(100%, -100%);
}
`, "",{"version":3,"sources":["webpack://./src/core/components/buttons/MenuButton/styles/MenuButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".custom-menu-button__container{\n    display: flex;\n    flex-direction: column;\n    width: fit-content;\n    position: relative;\n    align-items:center ;\n}\n.MuiIconButton-root.custom-menu-button__icon-button{\n    padding: 0;\n}\n.custom-menu-button__label{\n    position: absolute;\n    font-size: 0.65rem;\n    transform: translateY(1rem);\n    cursor: pointer;\n}\n\n.custom-menu-button__badge .MuiBadge-anchorOriginTopRightRectangle{\n    transform:  translate(100%, -100%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
