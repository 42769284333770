// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-rental-object{
    width: 100%;
}
.select-rental-object-container label{
    padding-bottom: 15px;
}
#select-rental-object.MuiSelect-select:focus{
    background-color: transparent;
}
.select-rental-object__form{
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Settings/components/AgreementObjectSelect/styles/RentalObjectSelect.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,oBAAoB;AACxB;AACA;IACI,6BAA6B;AACjC;AACA;IACI,WAAW;AACf","sourcesContent":[".select-rental-object{\n    width: 100%;\n}\n.select-rental-object-container label{\n    padding-bottom: 15px;\n}\n#select-rental-object.MuiSelect-select:focus{\n    background-color: transparent;\n}\n.select-rental-object__form{\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
