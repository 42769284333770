// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.extra-service-order.modal-items{
    padding: 0;
}
.modal-items__action-buttons{
    padding: 31px 0 15px  ;
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
}
.modal-items__action-button.MuiButton-text{
    border-radius: 150px;
    font-weight: 600;
    line-height: 18px;
    width: 100%;
    padding: 11px 16px;
    /*text-transform: none;*/
}
.modal-items__error-container{
    width: 100%;
    padding-top: 1rem;
}

.modal-items__action-buttons--confirm.MuiButton-text{
    background-color: var(--app-fill-color-primary-one);
    color: var(--app-primary-bg-color-white)
}
.modal-items__action-buttons--terminate.MuiButton-text{
    background-color: var(--app-border-secondary-color);
    color: var(--app-primary-bg-color-white)
}
.modal-window.confirmation-modal-window .MuiDialog-paper{
    max-width: 520px;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/modalsPortalsNotices/ConfirmationRequestModal/ConfirmationRequestModal.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;AACA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,kBAAkB;IAClB,wBAAwB;AAC5B;AACA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,mDAAmD;IACnD;AACJ;AACA;IACI,mDAAmD;IACnD;AACJ;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".extra-service-order.modal-items{\n    padding: 0;\n}\n.modal-items__action-buttons{\n    padding: 31px 0 15px  ;\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    column-gap: 8px;\n}\n.modal-items__action-button.MuiButton-text{\n    border-radius: 150px;\n    font-weight: 600;\n    line-height: 18px;\n    width: 100%;\n    padding: 11px 16px;\n    /*text-transform: none;*/\n}\n.modal-items__error-container{\n    width: 100%;\n    padding-top: 1rem;\n}\n\n.modal-items__action-buttons--confirm.MuiButton-text{\n    background-color: var(--app-fill-color-primary-one);\n    color: var(--app-primary-bg-color-white)\n}\n.modal-items__action-buttons--terminate.MuiButton-text{\n    background-color: var(--app-border-secondary-color);\n    color: var(--app-primary-bg-color-white)\n}\n.modal-window.confirmation-modal-window .MuiDialog-paper{\n    max-width: 520px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
