// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.extra-service-order-form__period-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0;
    gap: 16px;
}
.extra-service-order-form__input {
    width: 100%;
    margin: 0 0 16px 0;
}


@media screen and (min-width: 680px){
    .extra-service-order-form__period-container{
        flex-direction: row;
    }
    .extra-service-order-form__input {
        width: 100%;
        max-width: 180px;

    }
    .extra-service-order-form__date-input--single{
        max-width: 100%;
    }

}
.extra-service-order-form__confirmation-data-container{
    padding-top: 8px;
}
.extra-service-order-form__submit-button-container{
    margin-top: 12px;
    padding: 8px 0 16px;
}
.extra-service-order-form__confirmation-data-item{
    padding: 8px 0;
}
.extra-service-order-form__confirmation-data-row{
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-direction: column;
}
@media screen  and (min-width: 480px){
    .extra-service-order-form__confirmation-data-row{
        flex-direction: row;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ExtraService/components/ExtraServiceOrderForm/ExtraServiceOrderForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,eAAe;IACf,SAAS;AACb;AACA;IACI,WAAW;IACX,kBAAkB;AACtB;;;AAGA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,WAAW;QACX,gBAAgB;;IAEpB;IACA;QACI,eAAe;IACnB;;AAEJ;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,cAAc;AAClB;AACA;IACI,aAAa;IACb,6BAA6B;IAC7B,WAAW;IACX,sBAAsB;AAC1B;AACA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".extra-service-order-form__period-container{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    padding: 16px 0;\n    gap: 16px;\n}\n.extra-service-order-form__input {\n    width: 100%;\n    margin: 0 0 16px 0;\n}\n\n\n@media screen and (min-width: 680px){\n    .extra-service-order-form__period-container{\n        flex-direction: row;\n    }\n    .extra-service-order-form__input {\n        width: 100%;\n        max-width: 180px;\n\n    }\n    .extra-service-order-form__date-input--single{\n        max-width: 100%;\n    }\n\n}\n.extra-service-order-form__confirmation-data-container{\n    padding-top: 8px;\n}\n.extra-service-order-form__submit-button-container{\n    margin-top: 12px;\n    padding: 8px 0 16px;\n}\n.extra-service-order-form__confirmation-data-item{\n    padding: 8px 0;\n}\n.extra-service-order-form__confirmation-data-row{\n    display: flex;\n    justify-content: space-around;\n    width: 100%;\n    flex-direction: column;\n}\n@media screen  and (min-width: 480px){\n    .extra-service-order-form__confirmation-data-row{\n        flex-direction: row;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
