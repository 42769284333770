// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-window.help-info-modal .MuiDialog-paper {
    max-width: 520px;
}

.help-info-modal-text {
    padding-top: 8px;
    padding-bottom: 24px;
}

.help-info-modal .modal-window__title h2 {
    padding: 0;
}


@media screen and (max-width: 480px) {
    .help-info-modal.modal-window .MuiDialogTitle-root {
        padding: 1rem 1rem 0 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/core/components/modalsPortalsNotices/LoginHelpModal/LoginHelpModal.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,UAAU;AACd;;;AAGA;IACI;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".modal-window.help-info-modal .MuiDialog-paper {\n    max-width: 520px;\n}\n\n.help-info-modal-text {\n    padding-top: 8px;\n    padding-bottom: 24px;\n}\n\n.help-info-modal .modal-window__title h2 {\n    padding: 0;\n}\n\n\n@media screen and (max-width: 480px) {\n    .help-info-modal.modal-window .MuiDialogTitle-root {\n        padding: 1rem 1rem 0 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
