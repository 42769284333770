// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inline-label-menu-button__label.custom-menu-button__label{
    position: relative;
    font-size: 1rem;
    transform: none;
    padding-left: 16px;
    line-height: 1.75;
}
.inline-label-menu-button__container.custom-menu-button__container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 4px;
    padding-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/buttons/InlineLabelMenuButton/InlineLabelMenuButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".inline-label-menu-button__label.custom-menu-button__label{\n    position: relative;\n    font-size: 1rem;\n    transform: none;\n    padding-left: 16px;\n    line-height: 1.75;\n}\n.inline-label-menu-button__container.custom-menu-button__container{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding-bottom: 4px;\n    padding-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
