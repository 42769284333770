// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-request-form__wrapper{
    padding: 1rem 0;
}
.service-request-form__form-title{
    margin: 0;
}
.service-request-form{
    word-break: break-word;
}
.service-request-form__files{
    padding: 16px 0;
}

.service-request-form__rental-object-select-label.MuiInputLabel-root.MuiFormLabel-root{
    font-size: 14px;
    transform: translate(7px, 10px) scale(0.75);
    font-weight: 700;
    background-color: white;
    z-index: 1;
    width: fit-content;
    display: block;
    position: relative;
    padding: 0 5px 0 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Service/components/ServiceRequestForm/ServiceRequestForm.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,SAAS;AACb;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,2CAA2C;IAC3C,gBAAgB;IAChB,uBAAuB;IACvB,UAAU;IACV,kBAAkB;IAClB,cAAc;IACd,kBAAkB;IAClB,qBAAqB;AACzB","sourcesContent":[".service-request-form__wrapper{\n    padding: 1rem 0;\n}\n.service-request-form__form-title{\n    margin: 0;\n}\n.service-request-form{\n    word-break: break-word;\n}\n.service-request-form__files{\n    padding: 16px 0;\n}\n\n.service-request-form__rental-object-select-label.MuiInputLabel-root.MuiFormLabel-root{\n    font-size: 14px;\n    transform: translate(7px, 10px) scale(0.75);\n    font-weight: 700;\n    background-color: white;\n    z-index: 1;\n    width: fit-content;\n    display: block;\n    position: relative;\n    padding: 0 5px 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
