// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.K7iQy4fegcKew7HOosxL{
    padding-top: 1rem;
    padding-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/forms/FormContainer/FormContainer.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":[".formContainer{\n    padding-top: 1rem;\n    padding-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `K7iQy4fegcKew7HOosxL`
};
export default ___CSS_LOADER_EXPORT___;
