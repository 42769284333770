// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-header-menu{
    padding-top: 39px;
    padding-bottom: 2px;
    box-sizing: border-box;
}
.sidebar-header-menu__user-preferences.custom-menu-button__container{
    display: none;
}
@media screen and (min-width: 1125px){
    .sidebar-header-menu{
        padding: 41px 39px 0 27px

    }
    .sidebar-header-menu__user-preferences.custom-menu-button__container{
        display: flex;
    }
}

.sidebar-header-menu__item{
    flex: 1;
    align-items: flex-start;
}

.sidebar-header-menu__item.sidebar-header-menu__user-preferences{
    justify-content: flex-start;
    width: fit-content;
    align-items: flex-start;
}

.sidebar-header-menu__item.sidebar-header-menu__user-preferences .custom-menu-button__icon-button{
    padding: 14px;
}

.desktop-sidebar__header-logo{
    width: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/menu/SidebarHeaderMenu/styles/SidebarHeaderMenu.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,aAAa;AACjB;AACA;IACI;QACI;;IAEJ;IACA;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,OAAO;IACP,uBAAuB;AAC3B;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".sidebar-header-menu{\n    padding-top: 39px;\n    padding-bottom: 2px;\n    box-sizing: border-box;\n}\n.sidebar-header-menu__user-preferences.custom-menu-button__container{\n    display: none;\n}\n@media screen and (min-width: 1125px){\n    .sidebar-header-menu{\n        padding: 41px 39px 0 27px\n\n    }\n    .sidebar-header-menu__user-preferences.custom-menu-button__container{\n        display: flex;\n    }\n}\n\n.sidebar-header-menu__item{\n    flex: 1;\n    align-items: flex-start;\n}\n\n.sidebar-header-menu__item.sidebar-header-menu__user-preferences{\n    justify-content: flex-start;\n    width: fit-content;\n    align-items: flex-start;\n}\n\n.sidebar-header-menu__item.sidebar-header-menu__user-preferences .custom-menu-button__icon-button{\n    padding: 14px;\n}\n\n.desktop-sidebar__header-logo{\n    width: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
