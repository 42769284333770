// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-header{
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 5;
}
.app-header .title{
    font-weight: 600;
    font-size: 1.325rem;
    flex-grow: 1;
}
.app-header__return-btn.MuiIconButton-root{
    font-size: 1rem;
    font-weight: 600;
    padding: 0 24px 0 0;
}
.app-header__return-btn-link{
    text-decoration: none;
}
.app-header__logo{
    width: 90px ;
    height: 32px;

}
.app-header__logo-container{
    display: flex;
    justify-content: center;
    width: 100%;
}
.app-header .app-header__logo-container{
    padding: 20px;
}
.app-header__wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.app-header__filter-icon.MuiButtonBase-root{
    padding: 0;
}
.header-icon__add-icon{
    transform: scale(0.75) rotate(
            45deg
    );
}

.app-header .filter-button{
    padding-left: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/layout/AppHeader/styles/AppHeader.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,MAAM;IACN,UAAU;AACd;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,YAAY;IACZ,YAAY;;AAEhB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;AACf;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;AAC/B;AACA;IACI,UAAU;AACd;AACA;IACI;;KAEC;AACL;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".app-header{\n    background-color: #fff;\n    position: sticky;\n    top: 0;\n    z-index: 5;\n}\n.app-header .title{\n    font-weight: 600;\n    font-size: 1.325rem;\n    flex-grow: 1;\n}\n.app-header__return-btn.MuiIconButton-root{\n    font-size: 1rem;\n    font-weight: 600;\n    padding: 0 24px 0 0;\n}\n.app-header__return-btn-link{\n    text-decoration: none;\n}\n.app-header__logo{\n    width: 90px ;\n    height: 32px;\n\n}\n.app-header__logo-container{\n    display: flex;\n    justify-content: center;\n    width: 100%;\n}\n.app-header .app-header__logo-container{\n    padding: 20px;\n}\n.app-header__wrapper{\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n}\n.app-header__filter-icon.MuiButtonBase-root{\n    padding: 0;\n}\n.header-icon__add-icon{\n    transform: scale(0.75) rotate(\n            45deg\n    );\n}\n\n.app-header .filter-button{\n    padding-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
