/**
 * Redux redux actions
 */
export const ACTION = {
    CORE: {
        INIT_NETWORK_STATUS: 'CORE/INIT_NETWORK_STATUS',
        NETWORK_STATUS_CHANGED: 'CORE/NETWORK_STATUS_CHANGED',
        APP_ACTIVE_STATUS_CHANGED: 'CORE/APP_ACTIVE_STATUS_CHANGED',
        COMPONENT_STARTED_LOADING: 'CORE/COMPONENT_STARTED_LOADING',
        COMPONENT_FINISHED_LOADING: 'CORE/COMPONENT_FINISHED_LOADING',
        SET_COMPONENT_ERROR: 'CORE/SET_COMPONENT_ERROR',
        RESET_COMPONENT_ERROR: 'CORE/RESET_COMPONENT_ERROR',
        FULL_VIEW_MODE_CHANGED: 'CORE/FULL_VIEW_MODE_CHANGED',
        SLIDE_PANEL_OPEN_STATE_CHANGED: 'CORE/SLIDE_PANEL_OPEN_STATE_CHANGED',
        IS_MOBILE_WIDTH_STATE_CHANGED: 'CORE/IS_MOBILE_WIDTH_STATE_CHANGED',
        PUSH_DASHBOARD_NOTIFICATION: 'CORE/PUSH_DASHBOARD_NOTIFICATION',
        CLEAR_DASHBOARD_NOTIFICATION: 'CORE/CLEAR_DASHBOARD_NOTIFICATION',
        SET_NOTIFICATION: 'CORE/SET_NOTIFICATION',
        CLEAR_NOTIFICATION: 'CORE/CLEAR_NOTIFICATION',
        LAYOUT_DATA_REFRESHED: 'CORE/LAYOUT_DATA_REFRESHED',
        REFRESH_DATA_CALLED: 'CORE/REFRESH_DATA_CALLED',
        MAKE_GRAPHQL_REQUEST: 'CORE/MAKE_GRAPHQL_REQUEST',
        DEVICE_GLOBAL_ERROR: 'CORE/DEVICE_GLOBAL_ERROR',
        DEVICE_ADD_ERROR: 'CORE/DEVICE_ADD_ERROR',
        DEVICE_REMOVE_ERROR: 'CORE/DEVICE_REMOVE_ERROR',
        CONVERT_BASE64_TO_URL: 'CORE/CONVERT_BASE64_TO_URL',
        RECEIVED_ERROR_GRAPHQL_RESPONSE: 'CORE/RECEIVED_ERROR_GRAPHQL_RESPONSE',
        WINDOW_WIDTH_RANGE_CHANGED: 'CORE/WINDOW_WIDTH_RANGE_CHANGED',
        ERROR_LOGGED: 'CORE/ERROR_LOGGED',
    },
    MENU: {
        MENU_ITEM_SELECTED: 'menu/MENU_ITEM_SELECTED',
        MENU_ITEM_PROPERTY_CHANGED: 'menu/MENU_ITEM_PROPERTY_CHANGED',
    },
    MESSAGING: {
        REQUEST_CHANNELS: 'REQUEST_CHANNELS',
        ADD_CHANNEL: 'ADD_CHANNEL',
        CHANGE_CHANNEL_FIELD: 'CHANGE_CHANNEL_FIELD',
        SELECT_CHANNEL: 'SELECT_CHANNEL',
        CHANGE_NEW_MESSAGE: 'CHANGE_NEW_MESSAGE',
        SEND_NEW_MESSAGE: 'SEND_NEW_MESSAGE',
        ADD_CHANNEL_MESSAGE: 'ADD_CHANNEL_MESSAGE',
        CREATE_CHANNEL: 'CREATE_CHANNEL',
        ADD_CHANNEL_KEY: 'ADD_CHANNEL_KEY',
        FETCH_CHANNEL_MESSAGES: 'FETCH_CHANNEL_MESSAGES',
        SET_CHANNEL_MESSAGE_LIST: 'SET_CHANNEL_MESSAGE_LIST',
        SUBSCRIBE_TO_CHANNEL_MESSAGES: 'SUBSCRIBE_TO_CHANNEL_MESSAGES',
        UNSUBSCRIBE_FROM_CHANNEL_MESSAGES: 'UNSUBSCRIBE_FROM_CHANNEL_MESSAGES',
        CHANNEL_VIEWED: 'CHANNEL_VIEWED',
        SET_HAS_UNREAD_MESSAGES: 'SET_HAS_UNREAD_MESSAGES',
        SET_CHANNELS_MAP: 'SET_CHANNELS_MAP',
    },
    ENVIRONMENT: {
        FETCH_ENVIRONMENT_SETTINGS: 'FETCH_ENVIRONMENT_SETTINGS',
        FETCH_ENVIRONMENT_SETTINGS_REQUEST: 'FETCH_ENVIRONMENT_SETTINGS_REQUEST',
        SET_ENVIRONMENT_SETTINGS: 'SET_ENVIRONMENT_SETTINGS',
    },
    FIREBASE: {
        GET_NEW_TOKEN: 'GET_NEW_TOKEN',
        SET_TOKEN: 'SET_TOKEN',
        SET_FIREBASE_USER: 'SET_FIREBASE_USER',
    },
    AGREEMENT: {
        FETCH_AGREEMENTS_ASYNC: 'FETCH_AGREEMENTS_ASYNC',
        FETCH_ALL_AGREEMENTS: 'FETCH_ALL_AGREEMENTS',
        INIT_AGREEMENTS_FETCHING: 'INIT_AGREEMENTS_FETCHING',
        AGREEMENTS_FETCHED: 'AGREEMENTS_FETCHED',
        AGREEMENT_SELECTED: 'AGREEMENT_SELECTED',
        ALL_AGREEMENTS_RECEIVED: 'ALL_AGREEMENTS_RECEIVED',
        INIT_AGREEMENTS_RECEIVED: 'INIT_AGREEMENTS_RECEIVED',
        DATA_UPDATED: 'AGREEMENT_DATA_UPDATED',
        CHANGE_LICENSE_PLATES: 'AGREEMENT/CHANGE_LICENSE_PLATES',
        LICENSE_PLATES_CHANGED: 'AGREEMENT/LICENSE_PLATES_CHANGED',
    },
    AGREEMENT_TEMP: {
        LIST_ITEM_SELECTED: 'AGREEMENT_LIST_ITEM_SELECTED',
        LIST_ITEM_SELECTED_BY_ID: 'AGREEMENT_LIST_ITEM_SELECTED_BY_ID',
        LOADING_STATE_CHANGED: 'AGREEMENTS/LOADING_STATE_CHANGED',
        TERMINATION_PROCESS_CALLED: 'AGREEMENT_TEMP/TERMINATION_PROCESS_CALLED',
        EXTENSION_PROCESS_CALLED: 'AGREEMENT_TEMP/EXTENSION_PROCESS_CALLED',
        REQUEST_MODAL_STATE_CHANGED: 'AGREEMENT_TEMP/REQUEST_MODAL_STATE_CHANGED',
        TERMINATION_PROCESS_INIT: 'AGREEMENT_TEMP/TERMINATION_PROCESS_INIT',
        EXTENSION_PROCESS_INIT: 'AGREEMENT_TEMP/EXTENSION_PROCESS_INIT',
        CHANGE_REQUEST_RESET: 'AGREEMENT_TEMP/CHANGE_REQUEST_RESET',
        CHANGE_REQUEST_DATE_CHANGED: 'AGREEMENT_TEMP/CHANGE_REQUEST_DATE_CHANGED',
        CHANGE_REQUEST_SUBMIT_CALLED: 'AGREEMENT_TEMP/CHANGE_REQUEST_SUBMIT_CALLED',
        REQUESTED_CHANGES_RESPONSE_RECEIVED: 'AGREEMENT_TEMP/REQUESTED_CHANGES_RESPONSE_RECEIVED',
        ADD_SELECTED_TO_BLOCK_LIST: 'AGREEMENT_TEMP/ADD_SELECTED_TO_BLOCK_LIST',
        REMOVE_SELECTED_FROM_BLOCK_LIST: 'AGREEMENT_TEMP/REMOVE_SELECTED_FROM_BLOCK_LIST',
    },
    AGREEMENT_OBJECT: {
        PUT_AGREEMENT_OBJECTS: 'PUT_AGREEMENT_OBJECTS',
        AGREEMENT_OBJECT_SELECTED: 'AGREEMENT_OBJECT_SELECTED',
        AGREEMENT_OBJECT_SELECTED_BY_ID: 'AGREEMENT_OBJECT_SELECTED_BY_ID',
    },
    RENTAL_OBJECT: {
        SELECT_RENTAL_OBJECT: 'SELECT_RENTAL_OBJECT',
        SAVE_SMART_HOME_CONFIG: 'SAVE_SMART_HOME_SETTINGS',
        REQUEST_METER_READINGS_DATA_INTO_STORE: 'REQUEST_METER_READINGS_DATA_INTO_STORE',
        RECEIVE_METER_READINGS: 'RECEIVE_METER_READINGS',
        PUT_METER_READINGS_DATA_INTO_STORE: 'PUT_METER_READINGS_DATA_INTO_STORE',
        SET_CHARTS_DATA: 'SET_CHARTS_DATA',
        CLEAN_CHARTS_DATA: 'CLEAN_CHARTS_DATA',
        CHANGE_CODE: 'CHANGE_CODE',
        SET_OBJECT_METERS: 'SET_OBJECT_METERS',
    },
    AUTH: {
        LOGIN_ASYNC: 'LOGIN_ASYNC',
        LOGOUT: 'LOGOUT',
        UPDATE: 'UPDATE',
        LOGIN_SUCCESS: 'LOGIN_SUCCESS',
        CHANGE_FIELD: 'FIELD',
        SET_ERROR_MESSAGE: 'ERROR',
        PREFILL_EMAIL_FIELD: 'PREFILL_EMAIL_FIELD',
        REQUEST_TO_REFRESH_EXPIRED_TOKEN: 'REQUEST_TO_REFRESH_EXPIRED_TOKEN',
        EXPIRED_TOKEN_REFRESHED: 'EXPIRED_TOKEN_REFRESHED',
        TOKEN_CHANGED: 'TOKEN_CHANGED',
        TOKENS_CHANGED: 'TOKENS_CHANGED',
        REFRESH_TOKEN_CHANGED: 'REFRESH_TOKEN_CHANGED',
    },
    AUTH_TEMP: {
        LOGIN_ASYNC: 'LOGIN_ASYNC',
        CLEAR_LOADING_STATE: 'CLEAR_LOADING_STATE',
    },
    INVOICE: {
        FETCH_ALL_INVOICES: 'FETCH_ALL_INVOICES',
        INVOICES_FETCHED: 'INVOICES_FETCHED',
        ALL_INVOICES_RECEIVED: 'ALL_INVOICES_RECEIVED',
        DATA_UPDATED: 'INVOICE_DATA_UPDATED',
        SINGLE_INVOICE_UPDATE: 'SINGLE_INVOICE_UPDATE',
        START_SINGLE_INVOICE_UPDATE: 'START_SINGLE_INVOICE_UPDATE',
        STOP_SINGLE_INVOICE_UPDATE: 'STOP_SINGLE_INVOICE_UPDATE',
    },
    INVOICE_TEMP: {
        REQUEST_PDF: 'INVOICE_TEMP/REQUEST_PDF',
        PDF_DATA_RECEIVED: 'INVOICE_TEMP/PDF_DATA_RECEIVED',
        PDF_DATA_STORED: 'INVOICE_TEMP/PDF_DATA_STORED',
        PDF_DATA_DROPPED: 'INVOICE_TEMP/PDF_DATA_DROPPED',
        SELECTED_INVOICE_ID_STORED: 'INVOICE_TEMP/SELECTED_INVOICE_ID_STORED',
        SELECTED_INVOICE_ID_DROPPED: 'INVOICE_TEMP/SELECTED_INVOICE_ID_DROPPED',
        SELECTED_INVOICE_TEMP_DATA_DROPPED: 'SELECTED_INVOICE_TEMP_DATA_DROPPED',
        PDF_URL_GENERATED: 'INVOICE_TEMP/PDF_URL_GENERATED',
        PDF_FILE_OPENED_ON_ANDROID_PLATFORM: 'PDF_FILE_OPENED_ON_NATIVE_PLATFORM',
    },
    SERVICE_REQUEST: {
        CHANGE_FIELD_VALUE: 'CHANGE_FIELD_VALUE',
        RESET_FORM: 'RESET_FORM',
        ADD_FILES: 'ADD_FILES',
        SET_NEW_REQUEST_ID: 'SET_NEW_REQUEST_ID',
    },
    USER_PROFILE: {
        CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
        REFRESH: 'REFRESH',
    },
    FILTER: {
        CHANGE_FILTER: 'CHANGE_FILTER',
        RESET_FILTER_GROUP: 'RESET_FILTER_GROUP',
        SET_FILTER_GROUPS: 'SET_FILTER_GROUPS',
        SET_SERVICE_REQUEST_TYPES_MAP: 'SET_SERVICE_REQUEST_TYPES_MAP',
    },
    LARVA_TEMP: {
        DASHBOARD: {
            LOADING_STARTED: 'LARVA_DASHBOARD_LOADING_STARTED',
            LOADING_COMPLETED: 'LARVA_DASHBOARD_LOADING_COMPLETED',
        },
    },
    LARVA: {
        CHANGE_LARVA_FIELD: 'LARVA_FIELD',
        CHANGE_LARVA_CHECKBOX_FIELD: 'CHANGE_LARVA_CHECKBOX_FIELD',
        DOOR_CODE_CREATED: 'DOOR_CODE_CREATED',
        RESET_LARVA_FORM: 'RESET_LARVA_FORM',
        RESET_LARVA_FORM_PIN_CODE_FIELD: 'RESET_LARVA_FORM_PIN_CODE_FIELD',
        ERROR_ON_SUBMIT_LARVA_FORM: 'ERROR_ON_SUBMIT_LARVA_FORM',
        VALIDATE_LARVA_FORM_FIELD: 'VALIDATE_LARVA_FORM_FIELD',
        UPDATE_LARVA_CARDS: 'UPDATE_LARVA_CARDS',
        SAVE_SMART_HOME_CONFIG: 'SAVE_SMART_HOME_CONFIG',
        SAVE_WEB_COMPONENTS_PROPS: 'SAVE_WEB_COMPONENTS_PROPS',
        SAVE_UNIT_WEB_COMPONENTS_PROPS: 'SAVE_UNIT_WEB_COMPONENTS_PROPS',
        SAVE_UNIT_CONNECTIONS: 'SAVE_UNIT_CONNECTIONS',
        RESET_LARVA: 'RESET_LARVA',
        SAVE_WEB_COMPONENTS_GROUP_PROPS: 'SAVE_WEB_COMPONENTS_GROUP_PROPS',
        DROP_CONNECTIONS: 'DROP_CONNECTIONS',
        PUT_OBJECT_UNITS: 'PUT_OBJECT_UNITS',
        PUT_UNIT_DEVICES_MAP: 'PUT_UNIT_DEVICES_MAP',
        UNIT_DEVICES_OBJECTS_MAP_INIT_ASYNC: 'UNIT_DEVICES_OBJECTS_MAP_INIT_ASYNC',
        PUT_DASHBOARD_UNIT_OBJECT: 'PUT_DASHBOARD_UNIT_OBJECT',
        LARVA_DASHBOARD_COMPONENT_MOUNTED: 'LARVA_DASHBOARD_COMPONENT_MOUNTED',
        LARVA_DASHBOARD_COMPONENT_UNMOUNTED: 'LARVA_DASHBOARD_COMPONENT_UNMOUNTED',
        DASHBOARD_UI_NODES_ADDED: 'DASHBOARD_UI_NODES_ADDED',
        QUEUE_ACTION: 'QUEUE_ACTION',
        HANDLE_QUEUED_ACTIONS: 'HANDLE_QUEUED_ACTIONS',
        PUT_API_UNITS: 'LARVA/PUT_API_UNITS',
        API_UNITS_REQUESTED: 'LARVA/API_UNITS_REQUESTED',
        API_UNITS_REQUEST_FAILED: 'LARVA/API_UNITS_REQUEST_FAILED',
        API_UNITS_FETCH_STARTED: 'LARVA/API_UNITS_FETCH_STARTED',
        API_DOOR_ACCESS_FETCH_STARTED: 'LARVA/API_DOOR_ACCESS_FETCH_STARTED',
        WEBSOCKET_CONNECTIONS_REQUESTED: 'LARVA/WEBSOCKET_CONNECTIONS_REQUESTED',
        CLEAR_ALL_DATA: 'LARVA/CLEAR_ALL_DATA',
    },
    LARVA_DOOR_ACCESS_TEMP: {
        SIGNAL_SELECT_FIRST_AVAILABLE_DOOR_ACCESS: 'SIGNAL_SELECT_FIRST_AVAILABLE_DOOR_ACCESS',
        SHOW_FIRST_AVAILABLE_DOOR_ACCESS: 'SHOW_FIRST_AVAILABLE_DOOR_ACCESS',
        SELECT_FIRST_AVAILABLE_DOOR_ACCESS_AND_REDIRECT: 'SELECT_FIRST_AVAILABLE_DOOR_ACCESS_AND_REDIRECT',
        DOOR_ACCESS_LIST_VIEW_DATA_ITEM_SELECTED: 'DOOR_ACCESS_LIST_VIEW_DATA_ITEM_SELECTED',
        DOOR_ACCESS_SELECTED: 'DOOR_ACCESS_SELECTED',
        DOOR_ACCESS_SELECTED_BY_ID: 'DOOR_ACCESS_SELECTED_BY_ID',
    },
    LARVA_DOOR_ACCESS: {
        SIGNALS: {
            NEW_DOOR_ACCESS_CREATED: 'NEW_DOOR_ACCESS_CREATED',
            REFRESH_DOOR_ACCESS: 'REFRESH_DOOR_ACCESS',
        },
        DOOR_CARDS_RECEIVED: 'DOOR_CARDS_RECEIVED',
        DOOR_CODES_RECEIVED: 'DOOR_CODES_RECEIVED',
        DOOR_ACCESS_LIST_VIEW_DATA_RECEIVED: 'DOOR_ACCESS_LIST_VIEW_DATA_RECEIVED',
        DOOR_ACCESS_MAP_RECEIVED: 'DOOR_ACCESS_MAP_RECEIVED',
        CHANGED_RIGHT_TO_MANAGE_ACCESSES: 'LARVA_DOOR_ACCESS/CHANGED_RIGHT_TO_MANAGE_ACCESSES',
        DOOR_ACCESS_FORM_COMMANDS: {
            CREATE_DOOR_ACCESS: 'CREATE_DOOR_ACCESS',
        },
        DOOR_ACCESS_COMMAND: {
            DOOR_ACCESS_DELETE_ASYNC: 'DOOR_ACCESS_DELETE_ASYNC',
            DOOR_ACCESS_UPDATE_PIN_ASYNC: 'DOOR_ACCESS_UPDATE_PIN_ASYNC',
            DOOR_ACCESS_UNSET_PIN_ASYNC: 'DOOR_ACCESS_UNSET_PIN_ASYNC',
            DOOR_ACCESS_SEND_SMS_ASYNC: 'DOOR_ACCESS_SEND_SMS_ASYNC',
            DOOR_ACCESS_SEND_EMAIL_ASYNC: 'DOOR_ACCESS_SEND_EMAIL_ASYNC',
            DOOR_ACCESS_UPDATE_USER_DATA: 'DOOR_ACCESS_UPDATE_USER_DATA',
        },
        DOOR_ACCESS_ACTION_STATUS: {
            DOOR_ACCESS_DELETED: 'DOOR_ACCESS_DELETED',
            DOOR_ACCESS_PIN_UPDATED: 'DOOR_CODE_PIN_UPDATED',
            DOOR_ACCESS_PIN_UNSET: 'DOOR_ACCESS_PIN_UNSET',
            DOOR_ACCESS_SMS_SENT: 'DOOR_CODE_SMS_SENT',
            DOOR_ACCESS_EMAIL_SENT: 'DOOR_CODE_EMAIL_SENT',
            DOOR_ACCESS_USER_DATA_UPDATED: 'DOOR_CODE_USER_DATA_UPDATED',
        },
    },
    LARVA_WS: {
        REQUEST_ERROR: 'LARVA_WS/REQUEST_ERROR',
        HANDLE_QUEDED_PENDING_REQUESTS: 'LARVA_WS/HANDLE_QUEDED_PENDING_REQUESTS',
        TRY_UPDATE_WS_CONNECTIONS: 'LARVA_WS/TRY_UPDATE_WS_CONNECTIONS',
        INIT_FETCH_UI_NODES: 'LARVA_WS/INIT_FETCH_UI_NODES',
        GET_CONNECTION: 'LARVA_WS/GET_CONNECTION',
        SAVE_WS_CONNECTION: 'LARVA_WS/SAVE_WS_CONNECTION',
        CLIENT_SAVED: 'LARVA_WS/CLIENT_SAVED',
        SAVE_CONNECTION: 'LARVA_WS/SAVE_CONNECTION',
        CONNECTION_SAVED: 'LARVA_WS/CONNECTION_SAVED',
        OPEN_CONNECTION: 'LARVA_WS/OPEN_CONNECTION',
        CONNECTION_OPENED: 'LARVA_WS/CONNECTION_OPENED',
        CLOSE_CONNECTION: 'LARVA_WS/CLOSE_CONNECTION',
        CONNECTION_CLOSED: 'LARVA_WS/CONNECTION_CLOSED',
        CHECK_CONNECTION: 'LARVA_WS/CHECK_CONNECTION',
        CONNECTION_STATUS: 'LARVA_WS/CONNECTION_STATUS',
        NODE_REQUEST: 'LARVA_WS/NODE_REQUEST',
        NODE_REQUEST_RESULT: 'LARVA_WS/NODE_REQUEST_RESULT',
        NODE_OUTPUT: 'LARVA_WS/NODE_OUTPUT',
        NODE_OUTPUT_RESULT: 'LARVA_WS/NODE_OUTPUT_RESULT',
        ADD_REQUEST_TO_QUEUE: 'LARVA_WS/ADD_REQUEST_TO_QUEUE',
        WS_CONNECTIONS_UPDATED: 'LARVA_WS/WS_CONNECTIONS_UPDATED',
        ADD_REQUEST_TO_CONNECTION_WAIT_QUEUE: 'LARVA_WS/ADD_REQUEST_TO_CONNECTION_WAIT_QUEUE',
        CLEAR_WS_CONNECTION: 'LARVA_WS/CLEAR_WS_CONNECTION',
        CLEAR_QUEUE: 'LARVA_WS/CLEAR_QUEUE',
        INIT_WS_CONNECTION: 'LARVA_WS/INIT_WS_CONNECTION',
        WS_CONNECTION_ERROR: 'LARVA_WS/WS_CONNECTION_ERROR',
    },
    SLIDE_IN_CONTEXT_ACTION: {
        FILTER: 'FILTER',
        RESET: 'RESET',
        OPEN_PANEL: 'OPEN_PANEL',
    },
    MENU_STATE_ACTION: {
        SET_ACTIVE_ITEM: 'SET_ACTIVE_ITEM',
        OPEN_SLIDE_IN_PANEL: 'OPEN_SLIDE_IN_PANEL',
    },
    FILE: {
        DATA_UPDATED: 'FILES_DATA_UPDATED',
        FETCH_ALL_FILES: 'FETCH_ALL_FILES',
        ALL_FILES_RECEIVED: 'ALL_FILES_RECEIVED',
        DOWNLOAD_FILE_REQUEST: 'DOWNLOAD_FILE_REQUEST',
        DOWNLOAD_FILE_ACTION: 'DOWNLOAD_FILE_ACTION',
        MARK_SELECTED_LIST_ITEM_BROKEN: 'FILE/MARK_SELECTED_LIST_ITEM_BROKEN',
    },
    FILE_TEMP: {
        LOADING_STATE_CHANGED: 'FILES_LOADING_STATE_CHANGED',
        PREVIEW_LOADING_STATE_CHANGED: 'FILE_TEMP/PREVIEW_LOADING_STATE_CHANGED',
        LIST_ITEM_SELECTED: 'FILES_LIST_ITEM_SELECTED',
        LIST_ITEM_SELECTED_BY_ID: 'FILES_LIST_ITEM_SELECTED_BY_ID',
        DOWNLOADING_STATE_CHANGED: 'FILE_DOWNLOADING_STATE_CHANGED',
        GET_PREVIEW_REQUEST: 'FILE_TEMP/GET_PREVIEW_REQUEST',
        PREVIEW_FILE_ACTION: 'FILE_TEMP/PREVIEW_FILE_ACTION',
        UPDATE_SELECTED_FILE_DATA: 'FILE_TEMP/UPDATE_SELECTED_FILE_DATA',
        DATA_URL_GENERATED: 'FILE_TEMP/DATA_URL_GENERATED',
        RESET_SELECTED: 'FILE_TEMP/RESET_SELECTED',
        MARK_SELECTED_BROKEN: 'FILE_TEMP/MARK_SELECTED_BROKEN',
    },
    TIPS_AND_TRICKS: {
        SET_ARTICLES: 'SET_ARTICLES',
    },
    SALTO: {
        SET_AGREEMENTS_PERMISSIONS: 'SET_AGREEMENTS_PERMISSIONS',
    },
} as const;

export enum co2MetersAction {
    SET_CO2_AND_METERS = 'CO2_METERS/SET_CO2_AND_METERS',
    SET_CURRENT_MONTH_INDEX = 'CO2_METERS/SET_CURRENT_MONTH_INDEX',
    CLEAN_CO2_AND_METERS = 'CO2_METERS/CLEAN_CO2_AND_METERS',
}

export enum Inception {
    UNIT_LIST_FETCHED = 'INCEPTION/UNIT_LIST_FETCHED',
    DOOR_OPEN_REQUESTED = 'INCEPTION/DOOR_OPEN_REQUESTED',
    DOOR_OPEN_RECEIVED = 'INCEPTION/DOOR_OPEN_RECEIVED',
    DOOR_LOCK_REQUESTED = 'INCEPTION/DOOR_LOCK_REQUESTED',
    DOOR_LOCK_RECEIVED = 'INCEPTION/DOOR_LOCK_RECEIVED',
    DOOR_UNLOCK_REQUESTED = 'INCEPTION/DOOR_UNLOCK_REQUESTED',
    DOOR_UNLOCK_RECEIVED = 'INCEPTION/DOOR_UNLOCK_RECEIVED',
    START_SESSION_ID_REFRESH = 'INCEPTION/START_SESSION_ID_REFRESH',
    UNIT_STATUS_REQUESTED = 'INCEPTION/UNIT_STATUS_REQUESTED',
    UNIT_STATUS_RECEIVED = 'INCEPTION/UNIT_STATUS_RECEIVED',
    POLLING_STARTED = 'INCEPTION/POLLING_STARTED',
    STOP_POLLING = 'INCEPTION/STOP_POLLING',
    ACTIVITY_CREATED = 'INCEPTION/ACTIVITY_CREATED',
}

export const {
    CORE,
    MENU,
    MESSAGING,
    ENVIRONMENT,
    FIREBASE,
    RENTAL_OBJECT,
    AUTH,
    AUTH_TEMP,
    INVOICE,
    INVOICE_TEMP,
    USER_PROFILE,
    FILTER,
    LARVA,
    LARVA_TEMP,
    LARVA_WS,
    LARVA_DOOR_ACCESS,
    LARVA_DOOR_ACCESS_TEMP,
    SERVICE_REQUEST,
    SLIDE_IN_CONTEXT_ACTION,
    MENU_STATE_ACTION,
    AGREEMENT,
    AGREEMENT_TEMP,
    AGREEMENT_OBJECT,
    FILE,
    FILE_TEMP,
    TIPS_AND_TRICKS,
    SALTO,
} = ACTION;
