// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-setting-select-label.MuiFormLabel-root{
    padding-bottom: 10px;
}
.language-setting__select{
    width: 100%;
}
.language-setting__select.MuiInput-underline:before{
    display: none;
}
.language-setting--standard{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.language-setting__icon{
    padding-right: 10px ;
    padding-bottom: 3px;
    box-sizing: border-box;
}
.language-setting--standard .language-setting__select{
    width: auto;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/LangSelect/styles/LangSelect.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,WAAW;AACf","sourcesContent":[".language-setting-select-label.MuiFormLabel-root{\n    padding-bottom: 10px;\n}\n.language-setting__select{\n    width: 100%;\n}\n.language-setting__select.MuiInput-underline:before{\n    display: none;\n}\n.language-setting--standard{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n.language-setting__icon{\n    padding-right: 10px ;\n    padding-bottom: 3px;\n    box-sizing: border-box;\n}\n.language-setting--standard .language-setting__select{\n    width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
