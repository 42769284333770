// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.main-action-btn.MuiButton-containedPrimary,
.main-action-btn{
    border-radius: 150px ;
    width: 100%;
    display: block;
    text-align: center;
    line-height: 1.75rem;
    background-color: var(--app-fill-color-primary-one);
    text-decoration: none;
    outline: none;
    padding: 10px;
    color: #fff;
    font-weight: 600;
    font-size: 1rem;
}

.main-action-btn.MuiButton-containedPrimary:hover {
    background-color: var(--app__action-submit-btn-background--hover);
}
.main-action-btn .MuiButton-label{
    display: flex;
}
.main-action-btn__label-text{
    text-transform: none;
    color: var(--app__action-submit-btn-color);

}
.main-action-btn__label-icon--loading{
    color: white;


}

.main-action-btn__label-icons-wrapper {
    position: relative;
}
.main-action-btn__label-icon{
    position: absolute;
    left: -1.5rem;
    bottom: 5px;
}

.main-action-btn.Mui-disabled{
    background-color: var(--app__action-submit-btn-background--inactive);
    color: var(--app__action-submit-btn-color--inactive);
}
.MuiCircularProgress-colorPrimary.main-action-btn__label-icon{
    color: var(--app-primary-bg-color-white);
}

.main-action-btn--disabled{
    background-color: var(--app__action-submit-btn-background--inactive);
    color: var(--app__action-submit-btn-color--inactive);
}
`, "",{"version":3,"sources":["webpack://./src/core/components/buttons/SubmitButton/styles/PrimaryActionBtn.css"],"names":[],"mappings":";AACA;;IAEI,qBAAqB;IACrB,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,oBAAoB;IACpB,mDAAmD;IACnD,qBAAqB;IACrB,aAAa;IACb,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,iEAAiE;AACrE;AACA;IACI,aAAa;AACjB;AACA;IACI,oBAAoB;IACpB,0CAA0C;;AAE9C;AACA;IACI,YAAY;;;AAGhB;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,oEAAoE;IACpE,oDAAoD;AACxD;AACA;IACI,wCAAwC;AAC5C;;AAEA;IACI,oEAAoE;IACpE,oDAAoD;AACxD","sourcesContent":["\n.main-action-btn.MuiButton-containedPrimary,\n.main-action-btn{\n    border-radius: 150px ;\n    width: 100%;\n    display: block;\n    text-align: center;\n    line-height: 1.75rem;\n    background-color: var(--app-fill-color-primary-one);\n    text-decoration: none;\n    outline: none;\n    padding: 10px;\n    color: #fff;\n    font-weight: 600;\n    font-size: 1rem;\n}\n\n.main-action-btn.MuiButton-containedPrimary:hover {\n    background-color: var(--app__action-submit-btn-background--hover);\n}\n.main-action-btn .MuiButton-label{\n    display: flex;\n}\n.main-action-btn__label-text{\n    text-transform: none;\n    color: var(--app__action-submit-btn-color);\n\n}\n.main-action-btn__label-icon--loading{\n    color: white;\n\n\n}\n\n.main-action-btn__label-icons-wrapper {\n    position: relative;\n}\n.main-action-btn__label-icon{\n    position: absolute;\n    left: -1.5rem;\n    bottom: 5px;\n}\n\n.main-action-btn.Mui-disabled{\n    background-color: var(--app__action-submit-btn-background--inactive);\n    color: var(--app__action-submit-btn-color--inactive);\n}\n.MuiCircularProgress-colorPrimary.main-action-btn__label-icon{\n    color: var(--app-primary-bg-color-white);\n}\n\n.main-action-btn--disabled{\n    background-color: var(--app__action-submit-btn-background--inactive);\n    color: var(--app__action-submit-btn-color--inactive);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
