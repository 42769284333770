import { formatMoneyValue } from 'core/utils/useMoneyHelper';
import { IInvoiceData } from 'invoice/types/IInvoiceData';
import { IInvoicePropsData } from 'invoice/types/IInvoicePropsData';
import { DateTime } from 'luxon';

export const invoicePropsDataToInvoiceData = (data: IInvoicePropsData): IInvoiceData => {
    const { sum, vat, currency, invoiceDate, dueDate, balanceDate, interestDate } = data;
    return {
        ...data,
        totalSum: formatMoneyValue(sum + vat ?? 0, currency),
        invoiceDate: DateTime.fromSQL(invoiceDate),
        dueDate: DateTime.fromSQL(dueDate),
        balanceDate: DateTime.fromSQL(balanceDate),
        interestDate: DateTime.fromSQL(interestDate),
        referenceNumberString: String(data.referenceNumber || data.referenceNumberString),
    };
};
