import useQueryErrorHandler from 'core/hooks/useQueryErrorHandler';
import { QueryStatusData } from 'core/hooks/useQueryHelper';
import { AxiosCreateRequestProps, ServiceData, ServiceRequestResponse } from '../../../types/serviceRequest';
import useAxiosMakeServiceRequest from './useAxiosMakeServiceRequest';
import { useMutation } from '@apollo/client';
import { mutationCreateServiceRequest } from './gql/mutationCreateServiceRequest';
import useQueryChannels from 'messaging/gql/useQueryChannels';

const useMutationMakeServiceRequest = (): ServiceRequestReturnType => {
    const [makeServiceRequest, { data, error, loading, called }] = useMutation(mutationCreateServiceRequest);
    const { errorHandler } = useQueryErrorHandler();

    const { refetchAndUpdate } = useQueryChannels(true);

    const { createRequest, axiosError, axiosData, axiosLoading, uploading } = useAxiosMakeServiceRequest();

    const createRequestWithUpdateChannels: typeof createRequest = async (...data) => {
        await createRequest(...data);
        await refetchAndUpdate();
    };

    const createServiceRequest = (serviceData: ServiceData) => {
        makeServiceRequest({ variables: { serviceData } })
            .then(() => refetchAndUpdate())
            .catch(errorHandler);
    };

    return {
        axiosData,
        data: axiosData || (data as any),
        error: axiosError || error,
        loading: axiosLoading || loading,
        called,
        createServiceRequest,
        createRequest: createRequestWithUpdateChannels,
        uploading,
    };
};

export default useMutationMakeServiceRequest;

type ServiceRequestReturnType = QueryStatusData & {
    axiosData: ServiceRequestResponse | null;
    createServiceRequest: (serviceData: ServiceData) => void;
    data: ServiceRequestResponse;
    called: boolean;
    createRequest: (props: AxiosCreateRequestProps) => void;
    uploading: number;
};
