// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-item-icon {
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--app__icon--default-fill-color, #5B6073);
    width: 40px;
    height: 40px;
    min-width: 40px;
}
.list-item-icon .icon-svg path {
    fill: white;
}

.list-item-icon--success {
    background-color: var(--app__icon--bg-fill-color-success);
}

.list-item-icon--warning {
    background-color: var(--app__icon--bg-fill-color-warning);
}
.list-item-icon--warning .icon-svg path {
    fill: var(--app__icon--text-color-warning);
}

.list-item-icon--danger {
    background-color: var(--app__icon--bg-fill-color-danger);;
}

.list-item-icon--disabled {
    background-color: var(--app__icon--bg-fill-color-disabled);
}
.list-item-icon--disabled .icon-svg path {
    fill: var(--app__icon--text-color-disabled);
}


.list-item-icon .icon-svg {
    width: 18px;
    height: 18px;
}

.page-listing__list-item--selected .list-item-icon--disabled {
    background-color: var(--app__icon--text-color-disabled);
}
.page-listing__list-item--selected .list-item-icon--disabled .icon-svg path {
    fill: var(--app__icon--bg-fill-color-disabled);
}
`, "",{"version":3,"sources":["webpack://./src/core/components/icons/ListItemIcon/ListItemIcon.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oDAAoD;IACpD,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;AACA;IACI,WAAW;AACf;;AAEA;IACI,yDAAyD;AAC7D;;AAEA;IACI,yDAAyD;AAC7D;AACA;IACI,0CAA0C;AAC9C;;AAEA;IACI,wDAAwD;AAC5D;;AAEA;IACI,0DAA0D;AAC9D;AACA;IACI,2CAA2C;AAC/C;;;AAGA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,uDAAuD;AAC3D;AACA;IACI,8CAA8C;AAClD","sourcesContent":[".list-item-icon {\n    box-sizing: border-box;\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: var(--app__icon--default-fill-color, #5B6073);\n    width: 40px;\n    height: 40px;\n    min-width: 40px;\n}\n.list-item-icon .icon-svg path {\n    fill: white;\n}\n\n.list-item-icon--success {\n    background-color: var(--app__icon--bg-fill-color-success);\n}\n\n.list-item-icon--warning {\n    background-color: var(--app__icon--bg-fill-color-warning);\n}\n.list-item-icon--warning .icon-svg path {\n    fill: var(--app__icon--text-color-warning);\n}\n\n.list-item-icon--danger {\n    background-color: var(--app__icon--bg-fill-color-danger);;\n}\n\n.list-item-icon--disabled {\n    background-color: var(--app__icon--bg-fill-color-disabled);\n}\n.list-item-icon--disabled .icon-svg path {\n    fill: var(--app__icon--text-color-disabled);\n}\n\n\n.list-item-icon .icon-svg {\n    width: 18px;\n    height: 18px;\n}\n\n.page-listing__list-item--selected .list-item-icon--disabled {\n    background-color: var(--app__icon--text-color-disabled);\n}\n.page-listing__list-item--selected .list-item-icon--disabled .icon-svg path {\n    fill: var(--app__icon--bg-fill-color-disabled);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
