// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-container.MuiCheckbox-colorSecondary.Mui-checked{
    color: var(--app-fill-color-primary-one);
    fill: var(--app-fill-color-primary-one);
    opacity: 1;
}
.checkbox-container.MuiCheckbox-colorSecondary.Mui-checked svg rect{
    color: var(--app-fill-color-primary-one);
    fill: var(--app-fill-color-primary-one);
    opacity: 1;
}

.checkbox-container.MuiIconButton-colorSecondary:hover,
.checkbox-container.MuiCheckbox-colorSecondary.Mui-checked:hover{
    background-color: transparent!important;
}

.checkbox-label .MuiFormControlLabel-label{
    padding-left: 9px;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/buttons/CheckBox/CheckBox.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,uCAAuC;IACvC,UAAU;AACd;AACA;IACI,wCAAwC;IACxC,uCAAuC;IACvC,UAAU;AACd;;AAEA;;IAEI,uCAAuC;AAC3C;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".checkbox-container.MuiCheckbox-colorSecondary.Mui-checked{\n    color: var(--app-fill-color-primary-one);\n    fill: var(--app-fill-color-primary-one);\n    opacity: 1;\n}\n.checkbox-container.MuiCheckbox-colorSecondary.Mui-checked svg rect{\n    color: var(--app-fill-color-primary-one);\n    fill: var(--app-fill-color-primary-one);\n    opacity: 1;\n}\n\n.checkbox-container.MuiIconButton-colorSecondary:hover,\n.checkbox-container.MuiCheckbox-colorSecondary.Mui-checked:hover{\n    background-color: transparent!important;\n}\n\n.checkbox-label .MuiFormControlLabel-label{\n    padding-left: 9px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
