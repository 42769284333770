// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-with-arrow-list .arrow-list-item{
    padding-top: 12px;
    padding-bottom: 18px;

}
`, "",{"version":3,"sources":["webpack://./src/core/components/lists/ItemWithArrowList/ItemWithArrowList.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;;AAExB","sourcesContent":[".item-with-arrow-list .arrow-list-item{\n    padding-top: 12px;\n    padding-bottom: 18px;\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
