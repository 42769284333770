import { gql, useLazyQuery, useQuery } from '@apollo/client';
import useQueryHelper, {
    ORDER_BY,
    ORDER_DIR,
    QUERY_FETCH_POLICY,
    QUERY_NO_CACHE_FETCH_POLICY,
    QueryStatusData,
} from '../../../../core/hooks/useQueryHelper';
import useQueryErrorHandler from '../../../../core/hooks/useQueryErrorHandler';
import { ServiceRequest } from '../../../types/serviceRequest';
import PAGE_CONTEXT from '../../../../core/datasets/page_context';
import { setObjectValueInStorage } from '../../../../core/utils/localStorage';
import { useDispatch } from 'react-redux';
import { FILTER_GROUP } from '../../../../core/datasets/filter';
import { getDefaultValuesForGroup } from '../../../../core/utils/useFiltersHelper';
import { setServiceRequestFilterGroups } from '../../../../core/redux/actions/filterActions';

const ServiceRequestsDocument = gql`
    query serviceRequests($orderBy: OrderBy, $orderDir: Sort, $filterBy: ServiceRequestFilterInput) {
        serviceRequests(orderBy: $orderBy, orderDir: $orderDir, filterBy: $filterBy) {
            id
            files {
                url
                id
                mimeType
                name
                fileName
            }
            rentalObject {
                rentalType
                id
                country
                city
                street
                houseNumber
                apartmentNumber
                roomNumber
            }

            title
            description
            submitDate
            status
            type
            serviceRequestType {
                id
                isDefault
                isActive
                internalTitle
                titles {
                    text
                    langCode
                }
            }
            messagingChannelKey
        }
    }
`;

const useQueryServiceRequest = (): QueryServiceRequestReturnType => {
    const dispatch = useDispatch();
    const { getFilterValue } = useQueryHelper();
    const filters = getFilterValue(PAGE_CONTEXT.SERVICE_REQUESTS);
    const variables = {
        orderDir: ORDER_DIR.desc,
        orderBy: ORDER_BY.submitDate,
        filterBy: filters,
    };
    const { errorHandler } = useQueryErrorHandler();

    const options = {
        variables: variables,
        ...QUERY_FETCH_POLICY,
        onError: errorHandler,
    };

    const updateServiceRequestFilters = () => {
        if (!filters && !!data && 'serviceRequests' in data) {
            setObjectValueInStorage(PAGE_CONTEXT.SERVICE_REQUESTS, data.serviceRequests);
            const groups = {
                [FILTER_GROUP.STATUS]: getDefaultValuesForGroup(FILTER_GROUP.STATUS, data.serviceRequests),
                [FILTER_GROUP.TYPE_ID]: getDefaultValuesForGroup(FILTER_GROUP.TYPE_ID, data.serviceRequests),
            };
            dispatch(setServiceRequestFilterGroups({ groups, context: PAGE_CONTEXT.SERVICE_REQUESTS }));
        }
    };

    const { loading, error, data } = useQuery(ServiceRequestsDocument, {
        ...options,
        onCompleted: updateServiceRequestFilters,
    });

    const [getServiceRequests, { loading: lazyLoading, error: lazyError, data: lazyData, called }] = useLazyQuery(
        ServiceRequestsDocument,
        {
            variables: variables,
            ...QUERY_NO_CACHE_FETCH_POLICY,
            onError: errorHandler,
        },
    );

    const serviceRequests: ServiceRequest[] = data?.serviceRequests || [];
    const lazyServiceRequests: ServiceRequest[] = lazyData?.serviceRequests || [];

    return {
        serviceRequests,
        getServiceRequests,
        loading: lazyLoading || loading,
        error: lazyError || error,
        lazyServiceRequests,
        called,
    };
};
export default useQueryServiceRequest;

type QueryServiceRequestReturnType = QueryStatusData & {
    serviceRequests: ServiceRequest[];
    getServiceRequests: () => void;
    lazyServiceRequests: ServiceRequest[];
    called: boolean;
};
