import { IChannelFromBE, IServiceProviderUser } from '../types/messages';

export { getTimeString } from './getTimeString';

export const sortChannelsByLastActivity = (channels?: IChannelFromBE[]): IChannelFromBE[] => {
    return [...(channels ?? [])].sort((a, b) => (Number(b?.lastActivity) ?? 0) - (Number(a?.lastActivity) ?? 0));
};

export const createParticipantList = (landlordName: string, channelInfo: IChannelFromBE | null): string => {
    const providersList = channelInfo?.serviceProviderUsers?.map((provider) => provider.fullName) ?? [];
    return [landlordName, ...providersList].join(', ');
};

export const createProvidersMap = (providers?: IServiceProviderUser[]): Record<string, string> => {
    if (!providers) return {};

    return providers.reduce<Record<string, string>>((acc, provider) => {
        acc[provider.externalUuid] = provider.fullName;

        return acc;
    }, {});
};
