// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password-form{
    padding: 1rem 0.5rem 3rem;
    max-width: 407px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResetPassword/components/ResetPasswordForm.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;AACpB","sourcesContent":[".reset-password-form{\n    padding: 1rem 0.5rem 3rem;\n    max-width: 407px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
