// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labeled-text{
    display: flex;
    flex-direction: column;
}
.labeled-text__label{
    font-size: 0.875rem;
    color: var(--app__secondary-text-color);
    text-overflow: ellipsis;
    overflow: hidden;
}
.labeled-text__primary{
    color: var(--app-text-color-primary);
    font-weight: 400;
    overflow-wrap: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/core/components/documentItems/LabeledText/LabeledText.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,mBAAmB;IACnB,uCAAuC;IACvC,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,oCAAoC;IACpC,gBAAgB;IAChB,yBAAyB;AAC7B","sourcesContent":[".labeled-text{\n    display: flex;\n    flex-direction: column;\n}\n.labeled-text__label{\n    font-size: 0.875rem;\n    color: var(--app__secondary-text-color);\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n.labeled-text__primary{\n    color: var(--app-text-color-primary);\n    font-weight: 400;\n    overflow-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
