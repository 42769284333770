// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.extra-service-order__error-container .alert-notice-snack {
    width: 100%!important;
    margin: 0;
    padding: 1rem 0 0;
    left: 0;
    right: 0;
    transform: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ExtraService/components/ExtraServiceRequestConfirmationModal/styles/ExtraServiceRequestConfirmationModal.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,SAAS;IACT,iBAAiB;IACjB,OAAO;IACP,QAAQ;IACR,eAAe;AACnB","sourcesContent":[".extra-service-order__error-container .alert-notice-snack {\n    width: 100%!important;\n    margin: 0;\n    padding: 1rem 0 0;\n    left: 0;\n    right: 0;\n    transform: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
